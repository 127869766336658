export default {
  "warehouse": {
    "average_unit_price": "Average unit price",
    "total_prices": "Total",
    "workday": "Working day",
    "add_product": "Add product",
    "confirm_and_pay": "Confirm & pay",
    "picture": "Picture",
    "product_name": "Product name",
    "status": "Status",
    "cancel_out_warehouse": "Cancel stocking out",
    "set_user_defined_box_no": "Setting user defined carton number",
    "acquisition_logistics": "获取物流方案",
    "warehouse_code": "Warehouse code",
    "label_processing": "Labeling process",
    "from_product_address": "Dispatching address",
    "to_product_address": "Delivery address",
    "receiving_address": "Delivery Address",
    "encasement_specification": "Packing specification",
    "in_warehouse_size": "Inbound specification",
    "in_warehouse_product_size": "入库产品尺寸",
    "in_warehouse_time": "Inbound time",
    "in_warehouse_weight": "Inbound weight",
    "in_warehouse_product_weight": "入库产品重量",
    "encasement_count": "Packing quantity",
    "product_gross_weight": "Product gross weight",
    "product_label": "Product Label",
    "not_select_service": "Choose no service",
    "transport_characteristics": "Shipping features",
    "box_no": "Carton number",
    "box_no_is_not_added": "Carton number hasn't been added.",
    "plan_no": "Plan number",
    "consignment_code": "Distribution code",
    "consignment_warehouse_code": "Distribution warehouse-warehouse code",
    "storage_code": "Stock warehouse code",
    "can_identification_content": "Recognizable content",
    "hit_torr_serve": "Pallet service",
    "tray_volume_restriction": "Volume limitation of each pallet",
    "tray_weight_limit": "Weight limitation of each pallet",
    "not_required": "Unrequired",
    "warehouse_no": "Warehouse code",
    "await_in_warehouse_count": "Quantity wating for stocking in",
    "in_warehouse_count": "Inbound quantity",
    "await_in_warehouse_box_count": "Carton quantity to be stocked in",
    "in_warehouse_box_count": "Inbound carton quantity",
    "on_warehouse_count": "Quantity in stock",
    "on_warehouse_usable_count": "Available quantity in stock",
    "on_warehouse_usable_box_count": "Available carton quantity in stock",
    "await_out_warehouse_count": "Quantity to be stocked out",
    "await_out_warehouse_box_count": "Carton quantity to be stocked out",
    "inventory_affiliation_warehouse": "Warehouse of inventory",
    "out_warehouse_no": "Outbound warehouse number",
    "product_inventory": "Product inventory",
    "inventory_total": "Total inventory",
    "storage_warehouse_total": "Total of stock warehouse",
    "storage_warehouse_inventory": "Inventory of stock warehouse",
    "delivery_warehouse_inventory": "Inventory of distribution warehouse",
    "storage_warehouse_in_plan": "Inbound plan of stock warehouse",
    "storage_in_plan_no": "Inbound number of stock warehouse",
    "consignment_warehouse_in_plan": "Inbound plan of distribution warehouse",
    "consignment_warehouse_total": "Total of distribution warehouse",
    "alter_product_info": "Modify product info ",
    "storage_logistics_info": "Stocking and logistics info",
    "you_need_at_least_one_product": "至少需要一个产品",
    "cannot_create_out_plan": "Can not create outbound plan",
    "we_no_have_your_goods_in_the_warehouse": "No inventory products in our warehouse.",
    "select_consignment": "Select product for distribution.",
    "consignment_manage": "SE Distribution Warehouse",
    "relevance_info": "Related info",
    "currency_code": "币种代码",
    "battery_type": "Battery type",
    "battery_ingredient": "Battery components",
    "apply_content": "Application content",
    "battery_capacity": "Battery capacity",
    "encasement_size": "Packing dimension",
    "encasement_weight": "Packing weight",
    "consignment_warehouse_verified_packaging_size": "Verified packaging dimension in distribution warehouse",
    "consignment_warehouse_verified_packaging_weight": "Verified packaging weight in distribution warehouse",
    "customs_declare_info": "Customs declared info",
    "declare_price": "Declared unit price",
    "english_trade_name": "English product name",
    "chinese_trade_name": "Chinese product name",
    "english_texture": "Texture in English",
    "chinese_texture": "Texture in Chinese",
    "is_a_brand": "是否有品牌",
    "label_dispose": "Label handling",
    "in_warehouse_status": "Inbound status",
    "in_warehouse_encasement_size": "Inbound packing dimension",
    "in_warehouse_encasement_weight": "Inbound packing weight",
    "approved_package_size": "Verify package dimension",
    "approved_package_weight": "Verify package weight",
    "in_warehouse_img": "Pictures of stocking in",
    "product_not_filled_full": "Incompleted product data",
    "upload_prove_file": "Upload proof files",
    "alias": "Alias",
    "customs_no": "HS code",
    "logistics_delivery_status": "Logistics handing over status",
    "create_in_warehouse_plan": "Create inbound plan",
    "create_out_warehouse_plan": "Create outbound plan",
    "plan_name": "Plan name",
    "in_plan_name": "Inbound plan name",
    "in_warehouse_no": "Inbound number",
    "in_warehouse_plan_no": "Inbound plan number",
    "out_warehouse_plan_no": "Outbound plan number",
    "out_plan_detail": "Outbound plan details",
    "out_plan_name": "Outbound plan name",
    "out_warehouse_time": "Outbound time",
    "approved_packaging_info": "Approved packaging info",
    "error_state": "Exception Status",
    "request_to_investigate": "Request investigation",
    "confirm_out_warehouse_plan": "Confirm outbound plan",
    "check_in_warehouse": "Checking inbound plan",
    "check_out_warehouse": "Checking outbound plan",
    "belonging_to_warehouse": "Warehouse belonging to",
    "add_box_no": "Add carton number",
    "select_out_warehouse_way": "Select outbound method",
    "add_box_info": "Add carton info",
    "add_plan_name": "Add plan name",
    "confirm_out_plan": "Confirm outbound plan",
    "please_input_plan_name": "Please enter plan name",
    "outer_box_label": "Carton label",
    "planned_quantity": "Planned quantity",
    "planned_box_quantity": "Planned carton quantity",
    "product_quantity": "Product quantity",
    "receive_box_quantity": "Received carton quantity",
    "transportation_plan_number": "Shipping plan number",
    "destination_type": "Destination type",
    "purpose_country_city": "Destination country/city",
    "plan_status": "Plan status",
    "payment": "Payment method",
    "selected_pallet_packing_serve": "Selected pallet service",
    "pallet_packing_tips": "（This service will influence your available logistics solution）",
    "anew_in_warehouse": "Restock in",
    "reserved_take_goods_info": "Reserve picking up info",
    "reserved_info_title": "Here may enter recognizable info of consignee such as waybill number, driver's identity info, etc.",
    "only_allow_input_letter_and_number": "Only letters and numbers available.",
    "out_warehouse": "Outbound warehouse",
    "transportation_delivery_way": "Shipping handing over method",
    "delivery_way": "Delivery Type",
    "transportation_delivery_status": "Shipping handing over status",
    "subsequent_steps": "Following up steps",
    "plan_details": "Plan details",
    "label_serve": "Label service",
    "paid": "Pay",
    "label_serve_paid": "Label service(Paid)",
    "out_warehouse_encasement_weight": "Packing weight for stocking out",
    "out_warehouse_encasement_size": "Packing dimension for stocking out",
    "please_select_transportation_or_delivery_way": "Please select handing over type",
    "please_select_the_pick_up_method": "Please select pick up method.",
    "pick_up_method": "Pick up method",
    "in_warehouse_type": "Inbound type",
    "in_target_warehouse": "Target warehouse for stocking in",
    "target_warehouse": "Target warehouse",
    "out_warehouse_type": "Outbound type",
    "selected_common_address": "Select an usual address",
    "inbound_target_warehouse": "Target warehouse for stocking in",
    "charge": "Charges",
    "address": "Address",
    "warehouse_address": "Warehouse address",
    "serial_number": "Sequence number",
    "created_plan": "Create plan",
    "target_warehouse_code": "Target warehouse code",
    "originate": "Shipping from",
    "destination": "Destination Address",
    "send_goods_address": "Consigner address",
    "detail_address": "Detailed address",
    "refresh_address": "Update usual address",
    "save_as_common_address": "Save as usual address",
    "common_address": "Usual address",
    "select_address": "Select address",
    "receive_goods_address": "Delivery address",
    "transport_goods_address": "Delivery address",
    "amazon_shipping_number": "Amazon shipping number",
    "shipment_tracking_number": "Shipment tracking number",
    "warehousing_number": "Inbound Number",
    "seller_id": "Seller ID",
    "tracking_shipment": "Tracking shipment",
    "tracking_no": "Tracking number",
    "product_no": "Product Item No.",
    "product_imag": "Product image",
    "product_packing_imag": "Product packaging image",
    "tracking_ticket_no": "Tracking Number",
    "select_warehouse_nonsupport_consignment": "Distribution service unsupported for current inventory warehouse, please change the warehouse for your inventory.",
    "logistics_way": "Logistics method",
    "please_select_delivery_way": "Please select delivery type",
    "fba_warehouse_code": "FBA warehouse code",
    "fbm_warehouse_code": "FBM warehouse code",
    "international_transport": "International shipping ",
    "product_info": "Product info",
    "encasement_info": "Packing info",
    "rests": "Others",
    "channel": "Channel",
    "express_sheet": "Waybill",
    "third_side_list": "3rd Party Waybill",
    "box": "Carton",
    "out_total_volume": "Outbound total volume",
    "out_total_weight": "Outbound total weight",
    "customs_clearance_info": "Customs clearance info",
    "loading_default_settings": "Loading default settings",
    "declared_price": "Declared value",
    "add_inbound_goods": "Add inbound products",
    "please_enter_product_name_product_number_seSku": "Please input product name,product number,SESKU.",
    "please_enter_box_no": "Please input carton number.",
    "dispose_in_warehouse_plan": "Handle inbound plan",
    "dispose_out_warehouse_plan": "Handle outbound plan",
    "send_shipping_in_warehouse_plan": "Shipping inbound plan",
    "need_operate": "Need to be handled",
    "logistics_scheme": "Logistics solution",
    "logistics_code": "物流代码",
    "logistics_type": "Logistics type",
    "reference_aging": "Referenced time frame",
    "freight": "Freight",
    "freight_estimate": "Estimated freight",
    "delete_transport_plan": "Delete shipping plan",
    "optional_services": "Optional services",
    "business_number": "Business number",
    "happen_warehouse_code": "Warehouse code of occurrence",
    "business_happen_country": "Country of business occurrence",
    "time": "Time",
    "business_type": "Business type",
    "in_warehouse_plan": "Inbound plan",
    "warehouse_type": "Warehouse type",
    "tracking_number": "Tracking number",
    "request_time": "Requesting time",
    "investigation_status": "Investigation status",
    "survey_result": "Investigation result",
    "end_time": "Ending time",
    "product_has_not_added": "Product hasn't been added",
    "error_customs_declaration": "Data line of Customs Application Form must be completed.",
    "please_input_search_product_no_name": "Please input product SESKU, name.",
    "total_box_count": "Total carton quantity",
    "total_volume": "Total volume",
    "total_encasement_weight": "Total packing weight",
    "table_in_amount_is_required": "Quantity is required in the form.",
    "please_add_products": "Please add product.",
    "additional_packing_material_selection": "Additional packaging material options",
    "additional_packing_material": "附加包装材料",
    "packing_material_selection": "包装材料选择",
    "no_additional_packaging_required": "No need addtional packaging.",
    "print_sesku_label": "Print SE label",
    "download_se_label": "下载SE标签",
    "create_allot_to_storage_of_plan": "Create allocation plan to stock warehouse.",
    "select_need_operate_consignment": "Select distribution warehouse requiring handling.",
    "select_goods_and_packaging": "Select product and packaging",
    "set_box_number": "Input packing quantity",
    "current_warehouse_usable_inventory": "Available inventory in this warehouse",
    "box_amount": "Carton quantity",
    "select_packaging_box": "Select packaging carton",
    "product_sku": "Product SESKU",
    "weight": "Weight",
    "data_error": "数据错误",
    "product_error": "Product Incorrect",
    "yes": "是",
    "no": "否",
    "packing_material_name": "Packaging material name",
    "materials": "Material",
    "outer_diameter_size": "External dimension",
    "material_fee": "Material fee",
    "total_weight_after_packing": "Total packing up weight",
    "space_utilization": "Space utilization",
    "create_box_info": "Create carton info",
    "allot_amount": "Allocation quantity",
    "allot_to_storage_plan_detail": "Allocation plan details to stock warehouse",
    "my_product": "My Product",
    "sku_number": "SKU quantity",
    "storage_warehouse": "Storage Warehouse",
    "on_warehouse_sku_number": "SKU quantity in warehouse",
    "inventory_box_count": "Inventory carton quantity",
    "estimated_inventory_quantity": "Estimated carton quantity",
    "consignment_warehouse": "Distribution Warehouse",
    "inventory_count": "Inventory quantity",
    "planing": "Planning",
    "await_pay_transport_plan": "Shipping Plan to be paid.",
    "in_pan": "Inbound Plan",
    "await_receive_in_pan": "Inbound Plan to be received.",
    "out_pan": "Stocking Out Plan",
    "submitted": "Submitted",
    "in_the_outbound_plan": "Stocking Out Plan in processing.",
    "await_logistics_pan": "Plan to be updated with Logistics Solution.",
    "consignment_order": "Distribution Order",
    "the_quantity_of_batch_import_order_is_p0": "批量导入订单数量为:{0}",
    "current_product_information": "当前产品信息",
    "await_operate_consignment_order": "Distribution Order to be handled",
    "placed_an_order": "Order placed",
    "order_in_process": "Oeder in processing",
    "create_full_container_in_appointment": "Create reservation for FCL inbound.",
    "add_in_plan": "Add Inbound Plan for this warehouse.",
    "create_in_appointment_warning": "Please select correct container type and input the container number, and deliver on date as reservation，delay or incorrect info may cause warehouse rejection.",
    "landing_charges": "Discharge Fee",
    "create_appointment": "Create A Reservation",
    "please_add_the_warehousing_plan_for_the_warehouse": "Please add the Inbound Plan for warehouse.",
    "reservation_number": "Reservation Number",
    "date_created": "Creating Date",
    "appointment_warehouse": "Reserved Warehouse",
    "container_type": "Container Type",
    "container_no": "Container Number",
    "date_of_reservation": "Reservation Date",
    "details_of_FCL_storage_booking": "Reservation details for FCL inbound.",
    "local_time": "Local Time",
    "select_warehouse": "Select Warehouse",
    "in_warehouse_code": "Inbound Warehouse Code",
    "contain_in_plan_count": "Contained Inbound Plan quantity.",
    "fcl_storage_reservation": "Reservation for FCL inbound",
    "reservation_number_or_cabinet_number": "Reservation Number or Container Number.",
    "total_volume_of_transport": "Total shipping volume",
    "gross_shipping_weight": "Total shipping weight",
    "create_similar_product": "Create Similar product",
    "tooltip_text_1": "User defined product quantity per carton.",
    "tooltip_text_2": "User defined dimension and weight info.",
    "tooltip_text_3": "Warehouse or Logistics Center scanned dimension and weight info.",
    "tooltip_text_4": "User defined dimension info.",
    "tooltip_text_5": "User defined weight info.",
    "tooltip_text_6": "No any surcharges included in estimated freight.",
    "plan_number_or_out_warehouse_number": "Plan Name or Outbound Number.",
    "cancel_plan": "Cancel Plan",
    "timed_out": "Timed Out",
    "liquidated_damages_tips": "Please confirm the reservation is real and valid, Breaking Reservation Fee will be charged if the reservation was broken.",
    "warn": "Warning",
    "reassociate_the_warning_info": "Relate new product to the carton will cause the modification of relevant Inbound Plan and Shipping Plan.Please be causion.",
    "return_of_the_source": "Return From",
    "the_goods_warehouse": "Receiving Warehouse",
    "return_the_parcel": "Return Parcel",
    "return_plan_no": "Return Plan Number",
    "returns_the_number_of": "Return Quantity",
    "received_quantity": "Stocked In Quantity",
    "parcel_tracking_number": "Return Parcel Tracking Number",
    "scannable_number": "Scannable Number",
    "adding_returned_products": "Add return product",
    "product_size": "Product packaging dimension",
    "product_dimensions": "产品尺寸",
    "product_of_size": "产品的包装尺寸",
    "product_weight": "Product Weight",
    "product_contains_the_weight_of_the_package": "产品含包装的重量",
    "identifiable_information": "Recognizable information",
    "product_SKU": "Product SKU",
    "returns_the_total": "Total return quantity",
    "the_parcel_status": "Parcel Status",
    "please_adding_returned_products": "Please add return product.",
    "product": "Product",
    "destruction_of_prompt_p0": "Disposal is default for unqualified product and Disposal Fee {0}/unit will be charged.",
    "agree_to_destroy_service_fee": "Agree auto-payment of Disposal Fee for unqualified product.",
    "agree_to_differential_charges": "Agree auto-payment of charges caused by the discrepancy of actual weight and estimated weight.",
    "destroyed": "Disposed",
    "received": "Received",
    "destruction_of_the_number": "Disposed Quantity",
    "quality_inspection_results": "Inspection Result",
    "the_processing_results": "Processing Result",
    "processing_records": "Processing Record",
    "destruction_handling_charge": "Disposal Handling Fee",
    "is_qualified_1": "Qualified",
    "is_qualified_2": "Unqualified",
    "creation_time": "Creating time",
    "update_time": "Update time",
    "source_country": "Origin Country",
    "recipients": "Recipient",
    "recipient_company": "Receiving Company",
    "phone": "Phone",
    "customer_number": "Client Order Number",
    "system_number": "System Order Number",
    "originating_country": "Origin Country",
    "operating_state": "Processing Status",
    "shipping_lines": "Shipping Route",
    "number": "Quantity",
    "actual_weight": "Actual Weight",
    "volume": "Volume",
    "channel_code": "Channel Code",
    "channel_name": "Channel Name",
    "turn_single_track": "Tracking Route",
    "total_weight": "Total Weight",
    "add_box": "Add Carton",
    "box_is_not_added": "No carton added",
    "size": "Dimension",
    "girth": "Girth",
    "add_single_box": "Add single carton",
    "not_applicable": "Unavailable",
    "print_waybill": "Print Shipping Label",
    "cancel_waybill": "Cancel Shipping Label",
    "check_the_track": "Check Tracking Info",
    "confirm_cancellation_of_waybill": "If confirm cancel shipping order?",
    "no_waybill_channel_available_at_present": "No available channel currently.",
    "please_select_the_receiving_warehouse_first": "Please select receiving warehouse first.",
    "no_approved_record": "No approval record.",
    "p0_is_not_repeat": "{0} cannot be repeated.",
    "FNSKU_and_other_unique_information": "FNSKU and other unique information.",
    "scannable_information_error": "Scannable information error.",
    "whether_to_keep_the_outer_packaging": "If retain the package material?",
    "need_to_keep_the_outer_packaging": "Need to retain the package material.",
    "do_not_keep_the_outer_packaging": "No need to retain the outer package.",
    "please_enter_the_correct_p0": "Please input correct {0}",
    "trajectory": "Track",
    "trajectory_status": "Tracking Info Status",
    "untraceable": "Untrackable",
    "modify_the_number_of_times": "Modification available times.",
    "total_cost": "Total Charges",
    "product_item_no": "Product Item No.",
    "print_out_the_list": "打印出库单",
    "product_label_name": "产品标签名称",
    "the_code_cannot_be_recognized_please_change_the_file": "无法识别编码,请更换文件",
    "apply_id": "应用ID",
    "license_application": "授权应用",
    "application_list": "授权列表",
    "permission": "权限",
    "privileged_time": "授权时间",
    "please_input_apply_name_or_id": "请输入应用名称或ID",
    "cancel_application": "取消授权",
    "texture": "Texture",
    "cancel_count": "取消数量",
    "cancel_box_count": "取消箱数",
    "cancel_order": "取消订单",
    "cancel_shipment": "申请取消发货",
    "relation_no": "相关单号",
    "are_you_sure_cancel_order": "你确定要取消订单吗?",
    "are_you_sure_cancel_shipment": "你确定要申请取消发货吗?",
    "cancel_shipment_processing": "取消发货- 处理中",
    "cancel_shipment_succeed": "取消发货- 成功",
    "cancel_shipment_unsuccessful": "取消发货- 失败",
    "product_no_format": "(只允许输入4-64长度的字母、数字、'-'、'_')",
    "product_no_format_hint": "请输入4-64长度的字母、数字、'-'、'_'",
    "out_plan": "Outbound plan",
    "in_plan": "Inbound Plan",
    "transport_plan": "Shipping Plan",
    "brand_info": "品牌信息",
    "brand_name": "品牌名称",
    "purpose_description": "用途",
    "have_name": "有品牌",
    "no_brand": "无品牌",
    "purpose_chinese_description": "中文用途",
    "purpose_english_description": "英文用途",
    "max_volume_weight": "最大容重",
    "when_the_total_number_of_products_is_greater_than_1_this_option_is_mandatory": "当产品总数量大于1时,该选项为必选",
    "received_by_warehouse_at_p0_has_been_stored_for_p1_days": "在{0} 由仓库接收，已存放{1}天",
    "the_return_hold_fee_of_p0_will_be_automatically_deducted_by_the_system_and_can_be_viewed_in_the_return_plan_details": "退货暂存费 {0} 将由系统自动扣除，并在退货计划详情中可查看该费用",
    "please_create_a_return_plan_before_the_return_arrives_at_warehouse": "请在退货到达赛易仓库前及时创建退货计划",
    "tips_for_creating_a_return_plan_1": "在赛易仓库接收退货后，将收取退货暂存费",
    "tips_for_creating_a_return_plan_2": "退货到达赛易仓库，{0}日无匹配的退回计划的，将自动销毁",
    "the_goods_with_package_number_p0_have_been_destroyed_time_of_entry_p1_time_of_destruction_p3": "包裹号为 {0} 的货物已被销毁，进库时间: {1}，销毁时间: {2}",
    "return_package_number": "退货包裹号",
    "compensation": "赔付",
    "whether_to_refund": "是否退款",
    "whether_to_compensate": "是否赔付",
    "import_products": "导入产品",
    "select_import_file": "选择导入文件",
    "click_download_template": "点击下载模板",
    "when_you_confirm_the_import_only_the_correct_product_data_will_be_imported": "注意: 确认导入时, 只有正确的产品数据才会被导入",
    "note_data_can_only_be_imported_if_all_data_is_correct": "注意: 只有数据全部正确才能够被导入",
    "please_first": "请先",
    "download_template": "Download template",
    "then_follow_the_prompts_to_complete": "然后根据提示填写完整",
    "consignment_order_bulk_import_text2": "单次最多导入100单",
    "consignment_order_bulk_import_text3": "当一单中有多个产品时，请保持相关单号一致。会根据第一个单号数据，自动合并产品到对应配送单中",
    "consignment_order_bulk_import_text4": "当物选择流类型为第三方面单时(Others)，可直接导入第三方面单图片文件，也可稍后在操作界面中上传其他类型文件",
    "american": "美国",
    "mexico": "墨西哥",
    "p0_is_abnormal_and_cannot_be_modified": "{0}存在异常无法修改",
    "packing_methods": "包装方式",
    "merge_packaging_tips": "将多个产品合并打包成一个包装，根据产品数量计费",
    "merge_packaging_fee": "合并包装费用",
    "merge_packaging_has_been_selected": "已选择合并包装",
    "merge_packaging_not_supported": "不支持合并包装",
    "fee_code": "费用代码",
    "standby_identification1": "备用识别信息1",
    "standby_identification2": "备用识别信息2",
    "returned_package_and_alternate_identification_information_are_unique": "退货包裹和备用识别信息是唯一的."
  }
}